import { createSlice } from '@reduxjs/toolkit';
import { StoreService } from '../../api/services';

const storeLocal = StoreService.getStoreLocal();
const initial = {
  simple: {
    email: storeLocal.simple?.email ?? '',
    mobile: storeLocal.simple?.mobile ?? '',
    name: storeLocal.simple?.name ?? '',
    address: storeLocal.simple?.address ?? '',
    description: storeLocal.simple?.description ?? '',
    category: storeLocal.simple?.category ?? 0
  },
  stepId: storeLocal.stepId ?? 0,
  products: storeLocal.products ?? [],
  workDayList: (storeLocal.workDayList ?? []) as any[],
  advance: {
    website: storeLocal.advance?.website ?? '',
    facebook_name: storeLocal.advance?.facebook_name ?? '',
    facebook_link: storeLocal.advance?.facebook_link ?? '',
    zalo_link: storeLocal.advance?.zalo_link ?? '',
    zalo_name: storeLocal.advance?.zalo_name ?? '',
    youtube_link: storeLocal.advance?.youtube_link ?? '',
    youtube_name: storeLocal.advance?.youtube_name ?? ''
  }
};

const store = createSlice({
  name: 'createStore',
  initialState: initial,
  reducers: {
    addWorkDay: (state, action) => {
      state.workDayList.push(action.payload);
    },
    deleteWorkDay: (state, action) => {
      state.workDayList = state.workDayList.filter((item, index) => item !== action.payload);
    },

    updateProduct: (state, action) => {
      const newProduct = action.payload;
      const index = state.products.findIndex((item) => item.key === newProduct.key);
      if (index >= 0) {
        state.products[index] = newProduct;
      }
    },

    addProduct: (state, action) => {
      state.products.push(action.payload);
    },

    removeProductByKey: (state, action) => {
      const { id } = action.payload;
      state.products = state.products.filter((item) => item.key !== id);
    }
  }
});

const { reducer, actions } = store;
export const {
  addWorkDay,
  deleteWorkDay,
  updateProduct,
  removeProductByKey,
  addProduct
} = actions;
export default reducer;
