import BaseService from './base.service';
import { DeviceService } from './index';

class AuthService extends BaseService {
  path = () => 'auth';

  login(email, password, authKey = '') {
    const deviceInfo = DeviceService.getDeviceInfo();
    return this.getAxiosClient()
      .post(`/${this.path()}/login`, {
        email,
        password, authKey,
        ...deviceInfo
      })
      .then((response) => {
        // @ts-ignore
        if (response.error) {
          return response;
        }
        if (response.data.user) {
          this.setUser(response.data.user);
        }

        return response.data;
      })
      .catch((error) => {
        throw this.getDataError(error);
      });
  }

  myAccount(data = null) {
    return this.getAxiosClient().post(`/${this.path()}/myAccount`, data);
  }

  register(data) {
    return this.getAxiosClient().post(`/${this.path()}/signUp`, data);
  }

  forgotPassword(data) {
    return this.getAxiosClient().post(`/${this.path()}/forgotPassword`, data);
  }

  resetPassword(data) {
    return this.getAxiosClient().post(`/${this.path()}/resetPassword`, data);
  }

  changePassword(data) {
    return this.getAxiosClient().post(`/${this.path()}/changePassword`, data);
  }

  async logout() {
    const deviceInfo = DeviceService.getDeviceInfo();
    await this.getAxiosClient().post(`/${this.path()}/logout`, deviceInfo);
    this.removeUser();
    DeviceService.removeDevice();
    return true;
  }

  setUrlLoginRedirect(url: string) {
    localStorage.setItem('urlLoginRedirect', url);
  }

  getUrlLoginRedirect() {
    return localStorage.getItem('urlLoginRedirect');
  }

  removeUrlLoginRedirect() {
    localStorage.removeItem('urlLoginRedirect');
  }

}

export default new AuthService();
