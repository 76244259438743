import { Suspense, lazy } from 'react';

import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import { Navigate } from 'react-router-dom';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Account

const Login = Loader(lazy(() => import('src/content/auth/Login')));
const Verify = Loader(lazy(() => import('src/content/auth/Verify')));
const ForgotPassword = Loader(lazy(() => import('src/content/auth/ForgotPassword')));
const ChangePassword = Loader(lazy(() => import('src/content/auth/ChangePassword')));
const Status404 = Loader(
  lazy(() => import('src/content/Status/Status404'))
);

const accountRoutes = [
  {
    path: '/',
    element: (
      <Guest>
        <Login />
      </Guest>
    )
  },
  {
    path: '/:code',
    element: (
      <Guest>
        <Login />
      </Guest>
    )
  },
  {
    path: 'dang-nhap',
    element: <Navigate to='/' replace />
  },
  {
    path: 'kich-hoat-tai-khoan/:code',
    element: (
      <Guest>
        <Verify />
      </Guest>
    )
  },
  {
    path: 'doi-mat-khau/:code',
    element: (
      <Guest>
        <ChangePassword />
      </Guest>
    )
  },
  {
    path: 'quen-mat-khau',
    element: (
      <Guest>
        <ForgotPassword />
      </Guest>
    )
  },

  {
    path: '*',
    element: <Status404 />
  }
];

export default accountRoutes;
